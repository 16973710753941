import { render, staticRenderFns } from "./MyBookFav.vue?vue&type=template&id=3c47579a&scoped=true&"
import script from "./MyBookFav.vue?vue&type=script&lang=js&"
export * from "./MyBookFav.vue?vue&type=script&lang=js&"
import style0 from "./MyBookFav.vue?vue&type=style&index=0&id=3c47579a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c47579a",
  null
  
)

export default component.exports