<template>
<div id="tableScope" class="tableScope text-center">
    <b-modal size="lg" v-model="showDownloadModal" :hide-header="true" :hide-footer="true">
        <BillDownload :invoiceId="billInvoice" />
    </b-modal>
    <b-table sticky-header :head-variant="headVariant" :fields="fields" :items="items" :busy="isBusy" responsive :no-border-collapse="true">
        <template v-slot:table-busy>
            <div class="text-center my-2" style="color:#042446">
                <b-spinner class="align-middle"></b-spinner>
            </div>
        </template>
        <template v-slot:cell(payment_status)="data" style="width:100px">
            <!-- blue = omise สำเร็จ
                danger = ล้มเหลว
                info = รอตรวจ
                linepay = สำเร็จของ linepay
                success = สำเร็จทั่วๆไป
                warning = สำเร็จของ true wallet -->
            <div class="success status" v-if="(data.value).includes('Success') || (data.value).includes('สำเร็จ') || (data.value).includes('ทำรายการเสร็จสิ้น (Truewallet)') || (data.value).includes('Success via linepay')">{{ data.value }}</div>
            <div class="deny status" v-else-if="(data.value).includes('ปฏิเสธ') || (data.value).includes('Fail via omise')">{{ data.value }}</div>
            <!-- <div class="success status" v-if="(items.payment_status_color).includes('blue','linepay','success','warning')">{{ data.value }}</div> -->
            <div class="wait status" v-else>{{ data.value }}</div>
            <!-- <div class="deny status" v-else-if="(items.payment_status_color).includes('danger')">{{ data.value }}</div> -->
        </template>
        <!-- <template v-slot:cell(payment_download)="data" style="width:100px">
            <span class="yellowBTN" v-if="data.value == 1" @click="showBillDownload(data.item.payment_id)">ดาวน์โหลด</span>
        </template> -->
    </b-table>
</div>
</template>

<script>
import axios from 'axios'
import BillDownload from "./BillDownload"
import {
    mapGetters
} from 'vuex'
export default {
    data() {
        return {
            isBusy: true,
            fields: [{
                    key: 'payment_id',
                    label: 'No.'
                },
                {
                    key: 'payment_date',
                    label: 'วัน'
                },
                {
                    key: 'payment_price',
                    label: 'จำนวนเงิน'
                },
                {
                    key: 'payment_method',
                    label: 'ช่องทาง'
                },
                {
                    key: 'payment_status',
                    label: 'สถานะ'
                },
                // {
                //     key: 'payment_download',
                //     label: 'ดาวน์โหลดใบเสร็จ'
                // },
            ],
            items: [],
            headVariant: "none",
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            showDownloadModal: 0,
            billInvoice: ''
        }
    },
    computed: {
        ...mapGetters(['GET_MODE'])
    },
    watch: {
        GET_MODE() {
            this.$emit("emitModeToParent")
            this.checkMode()
        }
    },
    components: {
        BillDownload
    },
    mounted() {
        this.getPaymentHistory()
        this.$emit("emitModeToParent")
        this.checkMode()
    },
    methods: {
        async getPaymentHistory() {
            this.isBusy = true
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/profile&submodule=payment", formData)
                    .then(res => {
                        this.items = res.data
                        // this.items.forEach(element => {
                        //     element["_cellVariants"] = { payment_id: 'info' }
                        // });
                        this.isBusy = false
                        //console.log("getPaymentHistory : ", res.data)
                    })
                    .catch(err => {
                        console.error("error : " + err);
                    });
            } catch (err) {
                console.error("error : " + err);
            }
        },
        showBillDownload(invoiceId) {
            this.showDownloadModal = 1
            this.billInvoice=invoiceId
        },
        checkMode() {
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('tableScope').classList.remove('darkmode')
            this.headVariant = "light"
        },
        darkmode() {
            document.getElementById('tableScope').classList.add('darkmode')
            this.headVariant = "dark"
        }
    }
}
</script>

<style scoped>
.tableScope {
    padding: 4% 4%;
    font-size: 13px;
}

.darkmode {
    background: #35363A !important;
}

.darkmode:deep(td){
    color: white !important;
}

.b-table-sticky-header {
    max-height: 400px;
}

.status {
    border-radius: 4px;
    width: 60%;
    margin: auto;
    font-size: 14px;
}

.success {
    color: #92C85D;
    background: #ECF6D5;
}

.wait {
    color: #6AA1DE;
    background: #E1EAF5;
}

.deny {
    color: #EE806B;
    background: #FFDBD5;
}
@media screen and (max-width: 500px) {
    .status {
        font-size: 12px;
    }
}
.yellowBTN{
    color: white;
    background-color: #F5D087;
    border-radius: 6px;
    padding: 3px 25px 3px 25px;
    font-size: 16px;
}
.yellowBTN:hover {
    background-color: #FAC762;
    cursor: pointer;
    font-weight: bold;
}
</style>
