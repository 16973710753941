<template>
<div>
    <div style="width: 775px;height: 470px;margin: auto;" v-if="$vssWidth>=1240">
        <swiper class="swiper" :options="swiperOption" style="height: 460px;">
            <swiper-slide v-for="(novel,index) in novels" :key="index">
                <b-card @click="click(index)" img-top tag="article" class="mb-2 bookCardSize text-center">
                    <b-card-img-lazy :id="'img'+index" class="novelCover" :src="novel.novel_cover" width="91" height="128"></b-card-img-lazy>
                    <b-card-sub-title class="mt-1 novelName">
                        <v-clamp :id="'title'+index" autoresize :max-lines="2" style="fontSize:13px; textAlign:center">{{novel.novel_title}}</v-clamp>
                    </b-card-sub-title>
                    <b-tooltip :target="'img'+index" variant="warning">
                        <span v-if="page=='Fav'">คลิ๊กเพื่ออ่าน</span>
                        <span v-if="page=='Buy'">คลิ๊กเพื่อดูตอนที่ซื้อ</span>
                    </b-tooltip>
                </b-card>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
    <div style="max-width: 775px;height: 470px;margin: auto;" v-if="$vssWidth<1240 && $vssWidth >=540">
        <swiper class="swiper" :options="swiperOption_tablet" style="height: 460px;">
            <swiper-slide v-for="(novel,index) in novels" :key="index">
                <b-card @click="click(index)" img-top tag="article" class="mb-2 bookCardSize text-center">
                    <b-card-img-lazy :id="'img'+index" class="novelCover" :src="novel.novel_cover" width="91" height="128"></b-card-img-lazy>
                    <b-card-sub-title class="mt-1 novelName">
                        <v-clamp :id="'title'+index" autoresize :max-lines="2" style="fontSize:13px; textAlign:center">{{novel.novel_title}}</v-clamp>
                    </b-card-sub-title>
                    <b-tooltip :target="'img'+index" variant="warning">
                        <span v-if="page=='Fav'">คลิ๊กเพื่ออ่าน</span>
                        <span v-if="page=='Buy'">คลิ๊กเพื่อดูตอนที่ซื้อ</span>
                    </b-tooltip>
                </b-card>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
    <div :style="'maxWidth:'+$vssWidth+'px'" v-if="$vssWidth<540">
        <swiper class="swiper" :options="swiperOption_mobile" style="height: 360px;">
            <swiper-slide v-for="(novel,index) in novels" :key="index">
                <b-card @click="click(index)" img-top tag="article" class="mb-2 bookCardSize text-center">
                    <b-card-img-lazy :id="'img'+index" class="novelCover" :src="novel.novel_cover" width="91" height="128"></b-card-img-lazy>
                    <b-card-sub-title class="mt-1 novelName">
                        <v-clamp :id="'title'+index" autoresize :max-lines="2" style="fontSize:13px; textAlign:center">{{novel.novel_title}}</v-clamp>
                    </b-card-sub-title>
                    <b-tooltip :target="'img'+index" variant="warning">
                        <span v-if="page=='Fav'">คลิ๊กเพื่ออ่าน</span>
                        <span v-if="page=='Buy'">คลิ๊กเพื่อดูตอนที่ซื้อ</span>
                    </b-tooltip>
                </b-card>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</div>
</template>

<script>
import VClamp from 'vue-clamp'
import router from '../../../router'

export default {
    name: 'BookCard',
    props: ['novels', 'page'],
    data() {
        return {
            swiperOption: {
                mousewheel: true,
                slidesPerView: 5,
                slidesPerColumn: 2,
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiperOption_tablet:{
                mousewheel: true,
                slidesPerView: 3,
                slidesPerColumn: 2,
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiperOption_mobile: {
                mousewheel: true,
                slidesPerView: 2,
                slidesPerColumn: 2,
                spaceBetween: 1,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            }
        }
    },
    components: {
        VClamp
    },
    methods: {
        click(index) {
            if (this.page == 'Fav') {
                router.push('/novel/'+ this.novels[index].novel_id)
            } else if (this.page == 'Buy') {
                this.$root.$emit('getChapter', true, this.novels[index].novel_id)
            }

        }
    }
}
</script>

<style scoped>
.swiper {
    height: 430px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    height: 200px;
    margin-bottom: 9px;
}

.bookCardSize {
    border: none;
    background: none;
    transform: translateZ(0px);
    transition-duration: .5s;
    margin: 5%;
}

.bookCardSize:hover {
    transform: scale(1.08);
    cursor: pointer;
}

.chapter {
    padding: 10%
}

.b-table-sticky-header {
    max-height: 400px;
}

@media screen and (max-width: 500px) {
    .swiper-slide {
        height: 170px;
    }
    .card-body{
        padding: 0px 10px 4px 10px
    }
}
</style>
