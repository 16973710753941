<template>
<div class="text-center" v-if="isLoadData">
    <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
</div>
<div v-else>
    <b-col v-if="novels.length == 0">
        <div>ไม่มีหนังสือ</div>
    </b-col>
    <b-col class="shelf" v-else>
        <div class="shelfDivider"></div>
        <BookShelf :novels="novels" page="Fav" />
    </b-col>
</div>
</template>

<script>
import BookShelf from "./Bookshelf"
import axios from 'axios'

export default {
    data() {
        return {
            isLoadData: false,
            novels: [],
            userInfo: JSON.parse(localStorage.getItem('UserInfo'))
        };
    },
    components: {
        BookShelf
    },
    mounted() {
        this.getNovels()
    },
    methods: {
        async getNovels() {
            this.isLoadData = true
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/profile&submodule=bookcheif", formData)
                    .then(res => {
                        // //console.log(res.data)
                        this.novels = res.data
                        this.isLoadData = false
                    })
                    .catch(err => {
                        console.error("error : " + err);
                    });
            } catch (err) {
                console.error("error : " + err);
            }
        }
    },
};
</script>

<style scoped>
.shelf {
    border: 1rem solid #A67864;
    border-radius: 10px;
    background: #FBF2EE;
}

.shelfDivider {
    position: absolute;
    width: 100%;
    top: 48%;
    left: 0px;
    height: 7px;
    background: #A67864;
    box-shadow: inset -3px -2px 4px rgba(0, 0, 0, 0.25);
}
</style>
